@font-face {
	font-family: 'Manrope';
	src: local('Manrope-Regular'), local('Manrope-Regular'), url('Manrope-Regular.woff2') format('woff2'),
		url('Manrope-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Manrope';
	src: local('Manrope-SemiBold'), local('Manrope-SemiBold'), url('Manrope-SemiBold.woff2') format('woff2'),
		url('Manrope-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Manrope';
	src: local('Manrope-Regular'), local('Manrope-Regular'), url('Manrope-Regular.woff2') format('woff2'),
		url('Manrope-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Manrope';
	src: local('Manrope-Bold'), local('Manrope-Bold'), url('Manrope-Bold.woff2') format('woff2'),
		url('Manrope-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
